import Vue from 'vue'
import axios from 'axios'
import store from '../../store/index'

if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = '/api/'
} else {
    axios.defaults.baseURL = 'http://localhost:3333/api/'
    // axios.defaults.baseURL = 'https://heimdall.stv.com.br/api/'
    // axios.defaults.baseURL = 'http://8c1a09ce44a4.sn.mynetname.net:3333/api/'
    // axios.defaults.baseURL = 'http://192.168.200.226:3333/api/'
}

const success = res => res
const error = err => {
    let message = err.response.data.message || JSON.stringify(err.response.data);
    // let errorCode = err.response.status;
    switch (err.response.status) {
        case 400:
            store.dispatch('resetSnack');
            store.dispatch("showErrorSnack", `Erro: ${message}`);
            return Promise.reject(err)
        case 401:
            store.dispatch('resetSnack');
            store.dispatch("showErrorSnack", "Não Autorizado");
            return Promise.reject(err)
        case 307:
            store.dispatch('resetSnack');
            store.commit('destroyUser');
            store.dispatch("showErrorSnack", "Sua Seção Expirou");
            // window.location = '/'
            return Promise.reject(err)
        case 405:
            store.dispatch('resetSnack');
            store.dispatch("showErrorSnack", `Sem Permissão para usar o Recurso ${err.config.url} - ${err.config.method}`);
            return Promise.reject(err)
        case 406:
            store.dispatch('resetSnack');
            store.dispatch("showErrorSnack", `Sem dados para o periodo selecionado`);
            return Promise.reject(err)
        case 410:
            store.dispatch('resetSnack');
            store.dispatch("showErrorSnack", `Erro não foi possivel obter o snapshot`);
            return Promise.reject(err)
            // break;
        case 500:
            store.dispatch('resetSnack');
            store.dispatch("showErrorSnack", `Erro no Servidor ${err.response.data.message || JSON.stringify(err.response.data)}`);
            return Promise.reject(err)
        default:
            store.dispatch('resetSnack');
            store.dispatch("showErrorSnack", `Codigo de Erro ${err.response.status} - ${err.response.data.message || JSON.stringify(err.response.data)}`);
            return Promise.reject(err);
    }
}

axios.interceptors.response.use(success, error)

Vue.use({
    install(Vue) {
        Vue.prototype.$http = axios
    }
})