exports.genQueryFromObj = (obj) => {
  let query = "?";
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      if (typeof obj[key] == 'function') continue;
      if (typeof obj[key] == 'undefined') continue;
      query += `${key}=${obj[key]}&`
    }
  }
  return query.substring(0, query.length - 1);
}

exports.url = (path) => {
  let retorno = path;

  let queryPosi = retorno.lastIndexOf('?');
  if (queryPosi > 0) {
    retorno = retorno.substring(0, queryPosi);
  }

  let explodedPath = retorno.split('/')
  if (!isNaN(explodedPath[explodedPath.length - 1])) {
    let lastDivider = retorno.lastIndexOf('/');
    let pathNoId = retorno.substring(0, lastDivider);
    retorno = pathNoId;
  }
  return retorno;
}