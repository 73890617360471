<template>
  <div class="recursos">
    <div class="form">
      <v-card class="mx-auto py-2" elevation="5" outlined>
        <v-card-title>
          Usuários do Sistema
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="getAll"> Atualizar Dados </v-btn>
        </v-card-title>

        <v-form>
          <v-container>
            <v-row>
              <v-checkbox label="Ativo" v-model="usuario.enabled"></v-checkbox>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  v-model="usuario.level"
                  :items="niveis"
                  label="Nivel de Acesso"
                  outlined
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="usuario.groupId"
                  :items="getGroupSelect"
                  label="Grupo"
                  outlined
                  required
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="usuario.name"
                  :counter="255"
                  label="Nome"
                  required
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="usuario.userName"
                  :counter="255"
                  label="Usuário"
                  required
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="6" v-if="!usuario.id">
                <v-text-field
                  v-model="usuario.password"
                  :counter="255"
                  label="Senha"
                  required
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="6" v-else>
                <v-text-field
                  v-model="usuario.newPassword"
                  :counter="255"
                  label="Mudar Senha"
                  required
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="usuario.description"
                  rows="2"
                  label="Descrição"
                  hint="Digite uma descrição da Permissão"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-spacer></v-spacer>
              <v-btn depressed color="warning" class="mr-2" @click="clear">
                Cancelar
              </v-btn>
              <v-btn depressed color="success" @click="save(usuario)">
                Salvar
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
      <v-row>
        <v-col>
          <Table
            :headers="header"
            :items="usuarios"
            @edit="edit($event)"
            :rows="15"
            @delete="delet($event.id)"
          />
        </v-col>
      </v-row>
    </div>
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
import Table from "./TablePerm";
import { mapGetters } from "vuex";
export default {
  name: "Usuarios",
  components: {
    Table,
  },
  computed: {
    ...mapGetters([
      "getGroups",
      "getGroupSelect",
      "getGroupNameById",
      "getGrupoHeaders",
    ]),
  },
  data: () => {
    return {
      text: "",
      snackbar: false,
      usuario: {
        id: null,
        enabled: true,
      },
      usuarios: [],
      recursos: [],
      niveis: [],
      header: [
        { text: "ID", value: "id" },
        { text: "Nivel", value: "level" },
        { text: "Nome", value: "name" },
        { text: "Usuário", value: "userName" },
        { text: "Descrição", value: "description" },
        { text: "Grupo", value: "groupName" },
        { text: "Ativo", value: "ativo" },
        { text: "Cadastrado", value: "criado" },
        { text: "Modificado", value: "atualizado" },
        { text: "Ações", value: "actions" },
      ],
    };
  },
  methods: {
    get() {
      this.$http("user").then((resp) => {
        resp.data.forEach((element) => {
          element.ativo = element.enabled ? "SIM" : "NÃO";
          element.criado = new Date(element.createdAt).toLocaleString();
          element.atualizado = new Date(element.updatedAt).toLocaleString();
          element.groupName = this.getGroupNameById(element.groupId);
        });
        this.usuarios = resp.data;
      });
      this.$store.dispatch("loadGrupos");
    },

    getNiveis() {
      this.$http("userlevel").then((resp) => {
        resp.data.forEach((element) => {
          element.text = `${element.id} - ${element.name} - (${
            element.description
          }) ${element.superUser ? "SUPERUSUÁRIO" : ""}`;
          element.value = element.id;
        });
        this.niveis = resp.data;
      });
    },
    save(usuario) {
      const metodo = usuario.id ? "put" : "post";
      const finalUrl = usuario.id ? `/${usuario.id}` : "";
      this.$http[metodo](`/user${finalUrl}`, usuario).then(() => {
        this.get();
        this.clear();
        (this.snackbar = true), (this.text = "Salvo com Sucesso");
      });
    },
    edit(rec) {
      this.usuario = rec;
      this.$vuetify.goTo(0, { easing: "easeInOutCubic", duration: 300 });
    },
    delet(id) {
      this.$http.delete(`/user/${id}`).then(() => {
        this.get();
        (this.snackbar = true), (this.text = "Deletado com Sucesso");
      });
    },
    getAll() {
      this.get();
      this.getNiveis();
    },
    clear() {
      this.usuario = {
        id: null,
        enabled: true,
        nvRecursoId: null,
        nvAcessoId: null,
        get: true,
        getParam: true,
        put: true,
        putParam: true,
        post: true,
        postParam: true,
        delete: true,
        deleteParam: true,
        description: "",
      };
    },
  },
  mounted() {
    this.getAll();
    if (this.$store.getters.getGroups.length == 1) {
      this.usuario.groupId = this.$store.getters.getGroups[0].id;
    }
  },
};
</script>

<style>
</style>